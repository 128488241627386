<template>
  <table>
    <tr>
      <th>ID</th>
      <th>Name</th>
      <th>Status</th>
      <th>Testdruck</th>
    </tr>
    <tr v-for="station in stations" :key='station.id'>
      <td>{{ station.id }}</td>
      <td>{{ station.name }}</td>
      <td :class="'state '+station.state">{{ station.statemsg }}</td>
      <td><button @click="printTestLabel(station.name)" >Test</button></td>
    </tr>
  </table>
</template>

<style lang="scss">

.state {
  &.ok {
    border-color: #0f0;
    color: #0f0;
    background-color: #efe;
  }
  &.printing {
    border-color: #00f;
    color: #00f;
    background-color: #ffe;
  }
  &.error {
    border-color: #f00;
    color: #f00;
    background-color: #eff;
  }
}
</style>

<script>
import store from '@/store'

export default {
  name: 'Brands',
  data() {
    return {
      online: {},
    }
  },
  methods: {
    printTestLabel(stationName) {
      window.socket.send(JSON.stringify(["station print test label", stationName]))
    }
  },
  computed: {
    stations(){
      return store.state.stations
    }
  }
}
</script>

