<template>
  <section class="stations">
    <Stations />
  </section>
</template>

<script>
// @ is an alias to /src
import Stations from '@/components/Stations.vue'

export default {
  name: 'StationsView',
  components: {
    Stations
  }
}
</script>

<style lang="scss">
</style>

